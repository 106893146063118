<template>
  <div class="responsible-card card table">
    <header class="card-header">
      <p class="card-header-title">
        <span>Responsáveis</span>
      </p>
      <div class="card-header-icon" aria-label="novo responsável">
        <router-link class="button is-primary" :to="`/admin/alunos/${id}/responsaveis/novo`">
          <i class="lni lni-plus mr-1"></i> <span>Novo Responsável</span>
        </router-link>
      </div>
    </header>
    <div class="card-content">
      <div v-if="responsibles.length > 0" class="table-container">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <td>Ações</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(responsible) in responsibles" :key="responsible.id">
              <td>{{ responsible.name }}</td>
              <td>{{ responsible.email }}</td>
              <td>
                <router-link class="button is-primary is-small mr-1" :to="`/admin/alunos/${id}/responsaveis/${responsible.id}/editar`" title="Editar Responsável">
                  <i class="lni lni-pencil-alt"></i>
                </router-link>
                <button class="button is-danger is-small" title="Excluir Responsável">
                  <i class="lni lni-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <empty-list v-else entity="responsável" />
    </div>
  </div>
</template>

<script>
import api from '@/api'
import EmptyList from '@/components/ui/EmptyList'

export default {
  name: 'ResponsibleCard',
  components: {
    EmptyList
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  async created () {
    api.get(`/responsibles/students/${this.id}`).then(res => {
      this.responsibles = res.data
    })
  },
  data () {
    return {
      responsibles: []
    }
  }
}
</script>
