<template>
  <layout>
    <page-title :icon="`lni lni-${id > 0 ? 'pencil' : 'plus'}`" :breadcrumb="breadcrumb">
      {{ id > 0 ? 'Editar Aluno' : 'Novo Aluno' }}
    </page-title>
    <div class="card mb-5">
      <div class="card-content">
        <div class="form">
          <div v-if="error" class="notification is-danger field">
            <button class="delete" @click="error = null"></button>
            {{ error }}
          </div>
          <div class="field">
            <label class="label" for="enrollment">Matrícula<asterisk /></label>
            <div class="control">
              <input v-model="enrollment" class="input" type="text" name="enrollment" id="enrollment" placeholder="Matrícula do aluno" ref="enrollment" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="name">Nome<asterisk /></label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="name" id="name" placeholder="Nome do aluno" ref="name" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="email">Email</label>
            <div class="control">
              <input v-model="email" class="input" type="text" name="email" id="email" placeholder="Email do aluno" ref="email" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="password">Senha<asterisk /></label>
            <div class="control">
              <input v-model="password" class="input" type="password" name="password" id="password" placeholder="Senha de acesso" ref="password" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label" for="birthDate">Data de Nascimento<asterisk /></label>
                <div class="control">
                  <input v-model="birthDate" class="input" type="date" name="birthDate" id="birthDate" placeholder="Data de Nascimento" ref="birthDate" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label" for="celular">Celular<asterisk /></label>
                <div class="control">
                  <the-mask v-model="phone" class="input" type="text" name="celular" id="celular" placeholder="Número do aluno" ref="phone" mask="(##) # #### ####" />
                </div>
              </div>
            </div>
          </div>
          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send()">Salvar Aluno</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" to="/admin/alunos">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <responsible-card v-if="id > 0" :id="id" />
  </layout>
</template>

<script>
import Swal from 'sweetalert2'
import api from '@/api'
import validator from 'validator'
import { format } from 'date-fns'
import { DASHBOARD, STUDENT_LIST, STUDENT_FORM } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Asterisk from '@/components/ui/Asterisk'
import { TheMask } from 'vue-the-mask'
import ResponsibleCard from '../responsable/ResponsibleCard'

export default {
  name: 'StudentForm',
  components: {
    Layout,
    PageTitle,
    Asterisk,
    TheMask,
    ResponsibleCard
  },
  created () {
    if (this.$route.params.id !== undefined) {
      Swal.fire({
        title: 'Carregando...',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      api.get(`/students/${this.$route.params.id}`).then(res => {
        if (res.status === 200) {
          this.id = res.data.id
          this.enrollment = res.data.enrollment
          this.name = res.data.name
          this.birthDate = format(new Date(res.data.birthDate), 'yyyy-MM-dd')
          this.email = res.data.email
          this.phone = res.data.phone
        } else {
          this.error = 'Não foi possivel carregar o professor'
        }

        Swal.close()
      })
    }
  },
  data () {
    return {
      id: 0,
      enrollment: '',
      name: '',
      birthDate: '',
      email: '',
      phone: '',
      login: '',
      password: '',
      error: null,
      enviando: false
    }
  },
  computed: {
    breadcrumb () {
      const form = { ...STUDENT_FORM, isActive: true }

      if (this.id > 0) {
        form.text = 'Editar Aluno'
      }

      return [
        DASHBOARD,
        STUDENT_LIST,
        form
      ]
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.enrollment === '') {
        return this.fieldInvalid('enrollment', 'Matrícula é obrigátorio')
      }
      this.error = null

      if (this.name === '') {
        return this.fieldInvalid('name', 'Nome é obrigátorio')
      }
      this.error = null

      if (this.email !== '') {
        if (!validator.isEmail(this.email)) {
          return this.fieldInvalid('email', 'Email inválido')
        }
      }
      this.error = null

      if (this.password === '' && this.id === 0) {
        return this.fieldInvalid('password', 'Senha é obrigátoria')
      }
      this.error = null

      if (!validator.isDate(this.birthDate)) {
        return this.fieldInvalid('birthDate', 'Data de nascimento inválida')
      }
      this.error = null

      if (this.phone === '') {
        return this.fieldInvalid('phone', 'Celular é obrigátorio')
      }
      this.error = null

      if (this.id === 0) {
        api.post('/students', {
          enrollment: this.enrollment,
          name: this.name,
          birthDate: this.birthDate,
          email: this.email,
          phone: this.phone,
          password: this.password
        }).then(res => {
          if (res.status === 201) {
            Swal.fire({
              title: 'Deseja adicionar responsável?',
              text: 'Você quer adicionar um responsável para este aluno?',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Adicionar',
              cancelButtonText: 'Não'
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push(`/admin/alunos/${res.data.id}/responsaveis`)
              } else {
                this.$router.push('/admin/alunos')
              }
            })
          } else {
            this.error = 'Aluno não foi criado'
            this.enviando = false
          }
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
        api.patch(`/students/${this.id}`, {
          enrollment: this.enrollment,
          name: this.name,
          birthDate: this.birthDate,
          email: this.email,
          phone: this.phone,
          password: this.password
        }).then(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$router.push('/admin/alunos')
            })
          } else {
            this.error = 'Aluno não foi atualizado'
          }

          this.enviando = false
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      }
    },
    fieldInvalid (ref, message) {
      this.error = message
      this.$refs[ref].focus()
      this.enviando = false
    }
  }
}
</script>
